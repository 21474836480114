<script lang="js">
import {defineComponent} from 'vue'
import apiConsumer from "@/components/ApiConsumer.vue";


export default defineComponent({
    name: "Header",
    data(){
      return {
          dataUser:Object()
      }
    },
    created() {
        this.dataUser=apiConsumer.getDataUser();
    },
    methods:{
        openSideBar(){
          setClassSideBar('flyOut-sidebar open');
        },
        showOptionsUser(){
            showOptionsUser_js();
        },
        showModalProducto(){
            console.log("1");
            this.$root.$emit("bv::show::modal", "modalProducto");
            console.log(2);
            let element = this.$refs.modal.$el
            $(element).modal('show')
        },
        logout(){
            console.log("Cerrando sesión...");
            apiConsumer.logout().then(data=>{
                console.log(JSON.stringify(data));
                this.$router.push('/');
                //window.location.href=origin;
            }).catch(result=>{
                console.log(JSON.stringify(result));
                this.$router.push('/');
                //window.location.href=origin;
            });
        },
    }
})

</script>

<template>

    <div class="header__dashboard">
        <div class="header__left">

            <div class="product__add">
                    <!--v-bind:to="{name:'agregarProducto',params:{id:1},query:{datos:'agregar'}}"-->
                    <router-link to="/agregarProducto"   class="product__item">
                        <div class="icon">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 1V15" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M1 8H15" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        Agregar producto {{dataUser.codigoRestaurant}}
                    </router-link>


            </div>
        </div>

        <div class="header__right">
            <div id="toggler__btn" v-on:click="openSideBar">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M22 12H3" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M22 4H13" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M22 20H13" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M7 7L2 12L7 17" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
            </div>


            <div class="dropdown__btn">
                <a href="#" class="notification__button drop__action--btn">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                                d="M12.0206 2.91C8.71058 2.91 6.02058 5.6 6.02058 8.91V11.8C6.02058 12.41 5.76058 13.34 5.45058 13.86L4.30058 15.77C3.59058 16.95 4.08058 18.26 5.38058 18.7C9.69058 20.14 14.3406 20.14 18.6506 18.7C19.8606 18.3 20.3906 16.87 19.7306 15.77L18.5806 13.86C18.2806 13.34 18.0206 12.41 18.0206 11.8"
                                stroke="#292D32"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                        />
                        <path
                                d="M13.8699 3.2C13.5599 3.11 13.2399 3.04 12.9099 3C11.9499 2.88 11.0299 2.95 10.1699 3.2C10.4599 2.46 11.1799 1.94 12.0199 1.94C12.8599 1.94 13.5799 2.46 13.8699 3.2Z"
                                stroke="#292D32"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                        />
                        <path
                                d="M15.0195 19.06C15.0195 20.71 13.6695 22.06 12.0195 22.06C11.1995 22.06 10.4395 21.72 9.89953 21.18C9.35953 20.64 9.01953 19.88 9.01953 19.06"
                                stroke="#292D32"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                        />
                    </svg>
                    <div class="dot"></div>
                </a>
                <ul class="dropdown__list dropdown__list--v2">
                    <li>
                        <h4 class="header__title">Notifications</h4>
                    </li>
                    <li>
                        <a href="#" class="notification__wrap">
                            <div class="icon">
                                <img src="assets/images/notification/avatar-01.png" alt="avatar" />
                            </div>
                            <div class="content">
                                <h5 class="title">
                                    <span class="text-gray">Jane Cooper</span>
                                    recently
                                    <span class="text-black">Cancelled order</span>
                                </h5>
                                <span>5 min ago</span>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="notification__wrap">
                            <div class="icon">
                                <img src="assets/images/notification/icon-01.png" alt="avatar" />
                            </div>
                            <div class="content">
                                <h5 class="title">
                                    <span class="text-gray">New Report</span>
                                    has been received
                                </h5>
                                <span>5 min ago</span>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="notification__wrap">
                            <div class="icon">
                                <img src="assets/images/notification/Icon-02.png" alt="avatar" />
                            </div>
                            <div class="content">
                                <h5 class="title">
                                    <span class="text-gray">Received</span>
                                    <span class="span">$10,000</span>
                                    usd for new shipment
                                </h5>
                                <span>5 min ago</span>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="notification__wrap">
                            <div class="icon">
                                <img src="assets/images/notification/avatar-01.png" alt="avatar" />
                            </div>
                            <div class="content">
                                <h5 class="title">
                                    <span class="text-gray">Jane Cooper</span>
                                    recently
                                    <span class="text-black">Cancelled order</span>
                                </h5>
                                <span>5 min ago</span>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="notification__wrap">
                            <div class="icon">
                                <img src="assets/images/notification/icon-01.png" alt="avatar" />
                            </div>
                            <div class="content">
                                <h5 class="title">
                                    <span class="text-gray">New Report</span>
                                    has been received
                                </h5>
                                <span>5 min ago</span>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="notification__wrap">
                            <div class="icon">
                                <img src="assets/images/notification/Icon-02.png" alt="avatar" />
                            </div>
                            <div class="content">
                                <h5 class="title">
                                    <span class="text-gray">Received</span>
                                    <span class="span">$10,000</span>
                                    usd for new shipment
                                </h5>
                                <span>5 min ago</span>
                            </div>
                        </a>
                    </li>
                    <li class="show__btn text-center">
                        <a href="#" class="solid__btn">Show All</a>
                    </li>
                </ul>
            </div>



            <div class="dropdown__btn">
                <a class="avatar__button drop__action--btn" v-on:click="showOptionsUser">
                    <img src="assets/images/img1.jpg" alt="avatar-img" />
                </a>
                <ul class="dropdown__list" id="ulOpcionesUsuario">
                    <li class="active">
                        <router-link to="/perfilUsuario">
                            <i class="fa-solid fa-user"></i>
                            Perfil usuario
                        </router-link>
                    </li>
                    <li>
                        <a v-on:click="logout">
                            <i class="fa-solid fa-arrow-right"></i>
                            Cerrar sesión
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

</template>



<style>

</style>