import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueApexCharts from 'vue3-apexcharts'

import {createVfm} from 'vue-final-modal'

import numeral from './utils/numeralUtil.js'

const vfm=createVfm();

const app=createApp(App);
app.config.globalProperties.$numeral=numeral;
app.use(store).use(router).use(VueApexCharts).use(vfm).mount('#app');