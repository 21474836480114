<script lang="js">
import {defineComponent} from 'vue'
import apiConsumer from "@/components/ApiConsumer.vue";
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/css/index.css';

export default defineComponent({
    name: "FormularioProducto",
  components: {Loading},
    created() {
        if(this.datos_!==null){
            this.datos=this.datos_;
        }
        this.dataUser=apiConsumer.getDataUser();
        apiConsumer.getCategorias().then(categorias=>{
           this.categorias=categorias;
        });
        apiConsumer.getImpresoras().then(impresoras=>{
           this.impresoras=impresoras;
        });
    },
    props:['datos_'],
    data(){
        return {
          isLoading:false,
            data:Object(),
            dataUser:null,
            impresoras:[],
            categorias:[],
            datos:{
                idproducto:'',
                nombreProducto:'',
                descripcionProducto:'',
                tamanioProducto:'',
                precio:'',
                codigoProducto:'',
                estado:true,
                idcategoria:0,
                idrestaurant:'',
                impresoras:[]
            }
        };
    },
    methods:{
        addProducto(){
            this.datos.nombreProducto=this.datos.nombreProducto.toUpperCase();
            this.datos.descripcionProducto=this.datos.descripcionProducto.toUpperCase();
            this.datos.codigoProducto=this.datos.codigoProducto.toUpperCase();
            this.datos.tamanioProducto=this.datos.tamanioProducto.toUpperCase();
            if(this.datos.nombreProducto==="" || this.datos.descripcionProducto==="" || this.datos.precio==="" || this.datos.idcategoria===""){
                mensaje("Los campos con * son obligatorios","info");
                return ;
            }
            this.datos.idrestaurant=this.dataUser.idrestaurant;
            this.isLoading=true;
            apiConsumer.addProducto(this.datos).then(data=>{
              this.isLoading=false;
                mensaje(data.message,data.type);
                if(data.type==="success"){
                    this.data=data;
                    this.datos.nombreProducto="";
                    this.datos.tamanioProducto="";
                    this.datos.precio="";
                    this.datos.codigoProducto="";
                    this.datos.estado=true;
                    this.datos.descripcionProducto="";
                    this.datos.impresoras=[];
                }
            });
        }
    },
    watch:{
        data:function (){
            this.$emit("data",this.data);
        }
    }
})
</script>

<template>

  <div class="vl-parent">
    <loading v-model:active="isLoading"
             :can-cancel="true"
             :is-full-page="true"
    />
  </div>

    <div class="dashboard-block">
        <div class="dashboard-block__title">
            <h4>Información. Los campos con * son obligatorios</h4>
        </div>
        <div class="form__wrapper form-padding">
            <div class="flex__form c-3">
                <div class="form-group">
                    <label for="nombre">Nombre *</label>
                    <input type="text" id="nombre" v-model.trim="datos.nombreProducto"  class="form-control" placeholder="" />
                </div>
                <div class="form-group">
                    <label for="category">Categoría * ({{datos.idcategoria}})</label>
                    <select  id="idcategoria" class="form-control" v-model="datos.idcategoria" >
                        <option v-for="categoria in categorias" v-bind:value=categoria.idcategoria>{{categoria.nombreCategoria}}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="tamanio">Tamaño </label>
                    <input type="text" id="tamanio" v-model.trim="datos.tamanioProducto" class="form-control" placeholder="" />
                </div>
                <div class="form-group">
                    <label for="precio">Precio *</label>
                    <input type="text" id="precio" v-model.trim="datos.precio" class="form-control" placeholder="" />
                </div>
                <div class="form-group">
                    <label for="codigo">Código </label>
                    <input type="text" id="codigo" v-model.trim="datos.codigoProducto" class="form-control" placeholder="" />
                </div>

            </div>
            <div class="form-group">
                <label for="descripcion">Descripción *</label>
                <textarea
                        name="short_escription"
                        class="form-control"
                        id="descripcion"
                        cols="30"
                        rows="4"
                        placeholder=""
                        v-model.trim="datos.descripcionProducto"
                ></textarea>
            </div>
            <div class="form-group">
                <label>Estado </label>
                <div class="custom-checkbox">
                    <input type="checkbox" name="estado" id="estado" v-model.trim="datos.estado"/>
                    <label for="estado">
                        <span class="inactive">Desactivado</span>
                        <span class="active">Activado</span>
                    </label>
                </div>
            </div>
            <div class="form-group" id="impresoras">
                <label>Impresoras </label>
                <div class="check__list--wrapper">
                    <div v-for="impresora in impresoras" class="check-item">
                        <input type="checkbox" :id=impresora.idimpresora :value=impresora.idimpresora v-model="this.datos.impresoras"/>
                        <label :for=impresora.idimpresora>{{impresora.nombreImpresora}}</label>
                    </div>
                </div>
            </div>

            <div class="btn__group flex-wrap">
                <button type="button" v-on:click="addProducto()" class="btn btn-gray-filled">Guardar información</button>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>