import numeral from 'numeral';

// Configuración para peso chileno
numeral.register('locale', 'es-cl', {
    delimiters: {
        thousands: '.',
        decimal: ','
    },
    currency: {
        symbol: '$',
        position: 'prefix'
    }
});

// Establecer la localización por defecto a español chileno
numeral.locale('es-cl');

export default numeral;