<script>
import {defineComponent} from 'vue'
import Template from "@/views/Template.vue";
import FormularioProducto from "@/components/productos/FormularioProducto.vue";

export default defineComponent({
    name: "AgregarProducto",
    components: {FormularioProducto, Template},
})
</script>

<template>

    <Template></Template>
  <!-- Main__container Start-->
    <div class="main__container">
        <!-- Main Inner Wrapper Start -->
        <div class="main__inner--wrapper">
            <!-- Product List Start -->
            <div class="row">
                <div class="col-12">
                    <div class="dashboard__breadcrumb">
                        <div class="breadcrumb__meta">
                            <ul>
                                <li>
                                    <a href="/productos" class="link"><i class="fa-solid fa-arrow-left me-2"></i> Regresar</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="dashboard__header">
                        <div class="d__header--left">
                            <h2 class="title">Agregar producto</h2>
                        </div>
                    </div>

                    <div class="dashboard__form">
                        <div class="row">

                            <div class="col-xxl-8">
                                <FormularioProducto :datos_="null"></FormularioProducto>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Product List End -->
        </div>
        <!-- Main Inner Wrapper End -->
    </div>
  <!-- Main__container End-->
</template>

<style scoped>

</style>