<script>

const urlApi=process.env.VUE_APP_API_URL;

function getOptionsRequest(method,dataUser){
    return {
        method: method,
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'token': dataUser.token,
            'idusuarioRest': dataUser.idusuarioRest,
            'idtipoUsuario': dataUser.idtipoUsuario
        }
    };
}
function getDataUser(){
    let ls=localStorage.getItem('dataUser');
    return JSON.parse(ls);
}
function setDataUser(dataUser){
    localStorage.setItem('dataUser',JSON.stringify(dataUser));
}
function getCategorias(){
    let dataUser=getDataUser();
    let options=getOptionsRequest("get",dataUser);
    return fetch(urlApi+"/getCategorias?idrestaurant="+dataUser.idrestaurant+"&opcion=1",options).then((response)=>response.json()).then(data=>{
        return data.categorias;
    }).catch(result=>{
        console.log(JSON.stringify(result));
        return null;
    });
}
function getProductos(url) {
    let dataUser = getDataUser();
    let options = getOptionsRequest("get", dataUser);
    return fetch(url,options).then((response)=>response.json()).then((data)=>{
        return data;
    }).catch(result=>{
        console.log(JSON.stringify(result));
        return null;
    });
}
function getUsuarios(url){
    console.log("URL en getusuarios: "+url);
    let dataUser = getDataUser();
    let options = getOptionsRequest("get", dataUser);
    return fetch(url, options).then((response) => response.json()).then(data => {
        return data;
    }).catch(result => {
        console.log(JSON.stringify(result));
        return null;
    });
}
function getTiposUsuario(){
    let dataUser = getDataUser();
    let options = getOptionsRequest("get", dataUser);
    return fetch(urlApi+"/getTiposUsuario", options).then((response) => response.json()).then(data => {
        return data.tiposUsuario;
    }).catch(result => {
        console.log(JSON.stringify(result));
        return null;
    });
}
function getUsuarioPorRUT(rut){
  let dataUser = getDataUser();
  let options = getOptionsRequest("get", dataUser);
  return fetch(urlApi+"/getUsuarioByRUT?rut="+rut, options).then((response) => response.json()).then(data => {
    return data.usuario;
  }).catch(result => {
    console.log(JSON.stringify(result));
    return null;
  });
}
function addCategoria(datos){
    let dataUser = getDataUser();
    let options = getOptionsRequest("get", dataUser);
    return fetch(urlApi + "/addCategoria?idrestaurant=" + dataUser.idrestaurant + "&datos=" + JSON.stringify(datos), options).then((response) => response.json()).then((data) => {
        return data;
    }).catch(result => {
        console.log(JSON.stringify(result));
        return null;
    });
}
function addUsuario(datos){
    let dataUser = getDataUser();
    let options = getOptionsRequest("get", dataUser);
    return fetch(urlApi + "/addUsuario?datos=" + JSON.stringify(datos), options).then((response) => response.json()).then((data) => {
        return data;
    }).catch(result => {
        console.log(JSON.stringify(result));
        return null;
    });
}
function getImpresoras(){
    let dataUser = getDataUser();
    let options = getOptionsRequest("get", dataUser);
    return fetch(urlApi+"/getImpresoras?idrestaurant="+dataUser.idrestaurant,options).then((response)=>response.json()).then((data)=>{
       return data.impresoras;
    }).catch(result=>{
        console.log(JSON.stringify(result));
        return null;
    });
}
function addProducto(datos){
    let dataUser = getDataUser();
    let options = getOptionsRequest("get", dataUser);
    return fetch(urlApi+"/addProducto?datos="+JSON.stringify(datos),options).then((response)=>response.json()).then((data)=>{
        return data;
    }).catch(result=>{
        console.log(JSON.stringify(result));
        return null;
    });
}
function getVentas_(url){
    let dataUser = getDataUser();
    let options = getOptionsRequest("get", dataUser);
    return fetch(url,options).then((response)=>response.json()).then(data=>{
        return data;
    }).catch(result=>{
        console.log(JSON.stringify(result));
        return null;
    });
}
function request(url,method){
    let dataUser = getDataUser();
    let options = getOptionsRequest(method, dataUser);
    return fetch(url,options).then((response)=>response.json()).then(data=>{
        return data;
    }).catch(result=>{
        console.log(JSON.stringify(result));
        return null;
    });
}
function imprimirVenta(idventa){
    let dataUser = getDataUser();
    let options = getOptionsRequest("get", dataUser);
    return fetch(urlApi+"/imprimirVenta?idventa="+idventa+"&idrestaurant="+dataUser.idrestaurant,options).then(response=>response.json()).then(data=>{
        return data;
    }).catch(result=>{
        console.log(JSON.stringify(result));
        return null;
    });
}
function getKey(){
    let dataUser = getDataUser();
    let options = getOptionsRequest("get", dataUser);
    return fetch(urlApi+"/getKey?idusuario="+dataUser.usuario.idusuario+"&idrestaurant="+dataUser.idrestaurant,options).then(response=>response.json()).then(data=>{
        return data.clave;
    }).catch(result=>{
        console.log(JSON.stringify(result));
        return null;
    });
}
function deleteVenta(idventa,clave){
    let dataUser = getDataUser();
    let options = getOptionsRequest("get", dataUser);
    return fetch(urlApi+"/deleteVenta?idventa="+idventa+"&idrestaurant="+dataUser.idrestaurant+"&clave="+clave,options).then(response=>response.json()).then(data=>{
        return data;
    }).catch(result=>{
        console.log(JSON.stringify(result));
        return null;
    });
}
function deleteProducto(idproducto){
  let dataUser = getDataUser();
  let options = getOptionsRequest("get", dataUser);
  return fetch(urlApi+"/deleteProductoV2?id="+idproducto,options).then(response=>response.json()).then(data=>{
    return data;
  }).catch(result=>{
    console.log(JSON.stringify(result));
    return null;
  });
}
function deleteIngrediente(idingrediente,idrestaurant){
  let dataUser = getDataUser();
  let options = getOptionsRequest("get", dataUser);
  return fetch(urlApi+"/deleteIngrediente?idingrediente="+idingrediente+"&idrestaurant="+idrestaurant,options).then(response=>response.json()).then(data=>{
    return data;
  }).catch(result=>{
    console.log(JSON.stringify(result));
    return null;
  });
}
function deleteCategoria(idcategoria,idcategoriaReemplazo,idrestaurant){
  let dataUser = getDataUser();
  let options = getOptionsRequest("get", dataUser);
  return fetch(urlApi+"/deleteCategoriaAdmin?idcategoria="+idcategoria+"&idrestaurant="+idrestaurant+"&idcategoriaReemplazo="+idcategoriaReemplazo,options).then(response=>response.json()).then(data=>{
    return data;
  }).catch(result=>{
    console.log(JSON.stringify(result));
    return null;
  });
}
function deleteUsuario(idusuario,idrestaurant){

  let data={
    idusuario:idusuario,
    idrestaurant:idrestaurant
  };

  let dataUser = getDataUser();
  let options = {
    method: 'post',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8; application/x-www-form-urlencoded',
      'token': dataUser.token,
      'idusuarioRest': dataUser.idusuarioRest,
      'idtipoUsuario': dataUser.idtipoUsuario
    },
    body:JSON.stringify(data)
  }

  console.log(options);
  return fetch(urlApi+"/deleteUsuario",options).then(response=>response.json()).then(data=>{
    return data;
  }).catch(result=>{
    console.log(JSON.stringify(result));
    return null;
  });
}
function getIniciosCaja(url){
    let dataUser = getDataUser();
    let options = getOptionsRequest("get", dataUser);
    return fetch(url,options).then((response)=>response.json()).then(data=>{
        return data;
    }).catch(result=>{
        console.log(JSON.stringify(result));
        return null;
    });
}
function imprimirInicioCaja(idinicioCaja){
    let dataUser = getDataUser();
    let options = getOptionsRequest("get", dataUser);
    return fetch(urlApi+"/imprimirInicioCaja?idinicioCaja="+idinicioCaja+"&idrestaurant="+dataUser.idrestaurant,options).then(response=>response.json()).then(data=>{
        return data;
    }).catch(result=>{
        console.log(JSON.stringify(result));
        return null;
    });
}
function getEstadisticas(){
    let dataUser = getDataUser();
    let options = getOptionsRequest("get", dataUser);
    return fetch(urlApi+"/getEstadisticas?idrestaurant="+dataUser.idrestaurant,options).then(response=>response.json()).then(data=>{
        return data;
    }).catch(result=>{
        console.log(JSON.stringify(result));
        return null;
    });
}
function getPedidosMesa(idmesa){
    let dataUser = getDataUser();
    let options = getOptionsRequest("get", dataUser);
    return fetch(urlApi+"/getPedidosMesa?idrestaurant="+dataUser.idrestaurant+"&idmesa="+idmesa+"&opcion=1",options).then(response=>response.json()).then(data=>{
        return data.pedidos;
    }).catch(result=>{
        console.log(JSON.stringify(result));
        return null;
    });
}
function deletePedidoMesa(idpedido,motivo){
    let dataUser = getDataUser();
    let options = getOptionsRequest("get", dataUser);
    return fetch(urlApi+"/deletePedidoMesa?idrestaurant="+dataUser.idrestaurant+"&idpedido="+idpedido+"&motivo="+motivo,options).then(response=>response.json()).then(data=>{
        return data;
    }).catch(result=>{
        console.log(JSON.stringify(result));
        return null;
    });
}
function logout(){
    let dataUser = getDataUser();
    let options = getOptionsRequest("get", dataUser);
    let url= urlApi+"/logout?idusuarioRest="+dataUser.idusuarioRest+"&idtipoUsuario="+dataUser.idtipoUsuario;
    return fetch(url,options).then((response)=>response.json()).then((data)=>{
        return data;
    }).catch(result=>{
        console.log(JSON.stringify(result));
        return null;
    });
}
function exportarInicioCaja(idinicioCaja){
    let dataUser = getDataUser();
    let options = getOptionsRequest("get", dataUser);
    let url= urlApi+"/exportarInicioCaja?idinicioCaja="+idinicioCaja;
    return fetch(url,options).then((response)=>response.json()).then((data)=>{
        return data;
    }).catch(result=>{
        console.log("Error: "+JSON.stringify(result));
        return null;
    });
}
function add_request(url_service,datos){
    let dataUser = getDataUser();
    let options = getOptionsRequest("get", dataUser);
    return fetch(urlApi + url_service+"?datos=" + JSON.stringify(datos)+"&idrestaurant="+dataUser.idrestaurant, options).then((response) => response.json()).then((data) => {
        return data;
    }).catch(result => {
        console.log(JSON.stringify(result));
        return null;
    });
}
function addCompra(compra,ingredientes){
    let dataUser = getDataUser();
    let options = getOptionsRequest("get", dataUser);
    return fetch(urlApi + "/addCompra"+"?compra="+JSON.stringify(compra)+"&ingredientes=" + JSON.stringify(ingredientes)+"&idrestaurant="+dataUser.idrestaurant+"&idusuario="+dataUser.usuario.idusuario, options).then((response) => response.json()).then((data) => {
        return data;
    }).catch(result => {
        console.log(JSON.stringify(result));
        return null;
    });
}
function deleteCompra(idcompra){
    let dataUser = getDataUser();
    let options = getOptionsRequest("get", dataUser);
    return fetch(urlApi + "/deleteCompra"+"?idcompra="+idcompra, options).then((response) => response.json()).then((data) => {
        return data;
    }).catch(result => {
        console.log(JSON.stringify(result));
        return null;
    });
}
function getIngredientesProducto(idproducto){
    let dataUser = getDataUser();
    let options = getOptionsRequest("get", dataUser);
    return fetch(urlApi + "/getIngredientesProducto"+"?idproducto="+idproducto, options).then((response) => response.json()).then((data) => {
        return data.producto;
    }).catch(result => {
        console.log(JSON.stringify(result));
        return null;
    });
}
function addIngredientesProducto(idproducto,ingredientes){
    let dataUser = getDataUser();
    let options = getOptionsRequest("get", dataUser);
    return fetch(urlApi + "/addIngredientesProducto?idproducto="+idproducto+"&ingredientes=" + JSON.stringify(ingredientes), options).then((response) => response.json()).then((data) => {
        return data;
    }).catch(result => {
        console.log(JSON.stringify(result));
        return null;
    });
}
async function setContrasenia(datos){
    let dataUser=getDataUser();
    return await $.ajax({
        headers: {
            'token': dataUser.token,
            'idusuarioRest': dataUser.idusuarioRest,
            'idtipoUsuario': dataUser.idtipoUsuario
        },
        url: urlApi + '/setContrasenia',
        type: 'post',
        data: {datos:JSON.stringify(datos)},
        dataType: 'json',
        success: function (data) {
            return data;
        },
        error: function (result) {
            return result;
        }
    });
}
async function uploadFile(file,url_service){
    let dataUser = getDataUser();
    let formData=new FormData();
    formData.append('idrestaurant',dataUser.idrestaurant);
    formData.append('file',file);

    return await $.ajax({
        headers: {
            'token': dataUser.token,
            'idusuarioRest': dataUser.idusuarioRest,
            'idtipoUsuario': dataUser.idtipoUsuario
        },
        url:urlApi+url_service,
        type:'post',
        data:formData,
        dataType:'json',
        contentType:false,
        processData:false,
        success:function(data) {
            return data;
        },
        error: function(result) {
            console.log(JSON.stringify(result));
            return result;
        }
    });
}
function getFormatRUT(rut){
    rut=rut.toUpperCase();
    if(rut.length>2){
        rut=rut.replace("-","");
        return rut.substring(0, rut.length -1) + "-" + rut.substring(rut.length - 1, rut.length);
    }
    return rut;
}
function formatPrice(value) {
    let val = (value/1).toFixed(0).replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}
async function login(datos){
    return await $.ajax({
        url:urlApi+"/verifyUsuario",
        type:'post',
        dataType:'json',
        data:{datos:JSON.stringify(datos)},
        success:function (data){
            return data;
        },error:function (result){
            console.log(JSON.stringify(result));
            return null;
        }
    });
}
export default{
    urlApi,
    getVentas_,
    getDataUser,
    setDataUser,
    getProductos,
    getUsuarios,
    getTiposUsuario,
    addCategoria,
    addUsuario,
    getImpresoras,
    getCategorias,
    addProducto,
    imprimirVenta,
    getKey,
    deleteVenta,
    getIniciosCaja,
    imprimirInicioCaja,
    getEstadisticas,
    getPedidosMesa,
    deletePedidoMesa,
    logout,
    exportarInicioCaja,
    request,
    add_request,
    addCompra,
    deleteCompra,
    getIngredientesProducto,
    addIngredientesProducto,
    setContrasenia,
    uploadFile,
    formatPrice,
    getFormatRUT,
    login,
    deleteProducto,
    getUsuarioPorRUT,
    deleteUsuario,
    deleteIngrediente,
    deleteCategoria
}

</script>
