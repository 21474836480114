import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import AgregarProducto from "@/views/pages/AgregarProducto.vue";


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/Login.vue')
  },
  {
    path: '/inicio',
    name: 'inicio',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/Inicio.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/productos',
    name: 'productos',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/Productos.vue')
  },
  {
    path: '/ventas',
    name: 'ventas',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/Ventas.vue')
  },
  {
    path: '/categorias',
    name: 'categorias',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/Categorias.vue')
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/Usuarios.vue')
  },
  {
    path: '/agregarUsuario',
    name: 'agregarUsuario',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/AgregarUsuario.vue')
  },
  {
    path: '/agregarCategoria',
    name: 'agregarCategoria',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/AgregarCategoria.vue')
  },
  {
    path: '/configuraciones',
    name: 'configuraciones',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/Configuraciones.vue')
  },
  {
    path: '/agregarProducto',
    //path: '/agregarProducto:id',
    name: 'agregarProducto',
    component:AgregarProducto
  },
  {
    path: '/cajas',
    name: 'cajas',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/Cajas.vue')
  },
  {
    path: '/inventario',
    name: 'inventario',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/Ingredientes.vue')
  },
  {
    path: '/agregarInventario',
    name: 'agregarInventario',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/AgregarInventario.vue')
  },
  {
    path: '/compras',
    name: 'compras',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/Compras.vue')
  },
  {
    path: '/agregarCompra',
    name: 'agregarCompra',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/AgregarCompra.vue')
  },
  {
    path: '/configurarProducto:id',
    name: 'configurarProducto',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/ConfigurarProducto.vue')
  },
  {
    path: '/perfilUsuario',
    name: 'perfilUsuario',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/PerfilUsuario.vue')
  },
  {
    path: '/impresoras',
    name: 'impresoras',
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/Impresoras.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
