<template>

    <SideBar></SideBar>


</template>
<script>
  import SideBar from "@/views/pages/SideBar.vue";


  export default {
    name:'Template',
      components: {SideBar},

  }
</script>
<style>

</style>